<template>
    <b-modal id="invite-user" size="xl" centered hide-footer v-model="isShowInvite" no-close-on-backdrop>
        <div class="modal-wrapper">
            <div class="h1 text-center">Приглашение на событие</div>
            <ValidationObserver ref="Invite">
                <b-form slot-scope="{ validate, errors }" @submit.prevent="handleSubmit">
                    <div class="row mb-3">
                        <div class="col-lg-6">
                            <label>Название события</label>
                            <ValidationProvider rules="required" name="Событие" tag="div">
                                <div class="form-group" slot-scope="{ valid, errors }">
                                    <v-select :class="errors.length ? 'is-invalid' : ''" placeholder="Выберите событие" :options="forums" v-model="forum" label="title">
                                        <template #no-options="{ search, searching, loading }">
                                            Извините, нет доступных событий
                                        </template>

                                        <template #option="{ imagePath, title }">
                                            <div class="forum-td">
                                                <img v-if="imagePath" :src="imagePath" alt="">
                                                {{ title }}
                                            </div>
                                        </template>
                                    </v-select>
                                    <b-form-invalid-feedback>
                                        {{ errors[0] }}
                                    </b-form-invalid-feedback>
                                </div>
                            </ValidationProvider>
                        </div>

                        <div class="col-lg-6">
                            <label>ФИО пользователя</label>
                            <user-search :isRequire="true" v-model="user"/>
                        </div>

                        <div v-if="forum && forum.price.length" class="col-lg-6">
                            <label>Тип билета</label>
                            <ValidationProvider rules="required" name="Тип билета" tag="div">
                                <div class="form-group" slot-scope="{ valid, errors }">
                                    <v-select :class="errors.length ? 'is-invalid' : ''" placeholder="Выберите тип билета" :options="forum.price" v-model="ticket" label="title">
                                        <template #no-options="{ search, searching, loading }">
                                            Извините, нет доступных билетов
                                        </template>

                                        <template #option="{ title, amount }">
                                            {{ title }} - {{ amount }} грн.
                                        </template>

                                        <template #option="{ title, amount }">
                                            {{ title }} - {{ amount }} грн.
                                        </template>
                                    </v-select>
                                    <b-form-invalid-feedback>
                                        {{ errors[0] }}
                                    </b-form-invalid-feedback>
                                </div>
                            </ValidationProvider>
                        </div>
                    </div>

<!--                    <div class="text-center mb-4">-->
<!--                        <div class="payment-cell">-->
<!--                            <b-form-checkbox switch size="lg" v-model="isWaitPayment">Ожидание оплаты</b-form-checkbox>-->
<!--                        </div>-->
<!--                    </div>-->

                    <div class="text-center mb-4">
                        <b-button type="submit" variant="primary submit pl-5 pr-5">пригласить</b-button>
                    </div>
                </b-form>
            </ValidationObserver>
        </div>
    </b-modal>
</template>

<script>
    import { mapActions } from 'vuex'
    import { errorsAlertApi } from '@/helpers/errorsAlertApi'
    import UserSearch from "../forms/UserSearch"

    export default {
        name: "InviteUser",
        components: {UserSearch},
        props: {
            forums: {
                type: Array,
                required: true
            }
        },
        methods: {
            ...mapActions([
                'getForumsList',
                'getUsers',
                'inviteUserForum'
            ]),
            resetUserForum(){
                this.forum = null;
                this.user = null;
                this.$nextTick(() => {
                    this.$refs.Invite.reset();
                });
            },
            async handleSubmit() {
                let isValid = await this.$refs.Invite.validate();
                if (isValid) {
                    const data = {
                        user: this.user._id,
                        forum: this.forum._id,
                        status: 'APPROVED'
                        // status: this.isWaitPayment ? "WAIT_PAYMENT": "APPROVED"
                    };

                    const language = this.user.languageCode;

                    if(this.ticket){
                        data.ticketTitle = this.ticket.title
                    }

                    this.inviteUserForum({ data, language }).then(invite => {
                        this.$emit('invitedUser', invite);
                        // this.isWaitPayment = false;
                        this.$bvModal.hide('invite-user');
                    })
                        .catch(error => {
                            errorsAlertApi(error);
                            this.$bvModal.hide('invite-user');
                        });
                }
            },
        },
        watch: {
            isShowInvite(value){
                if(!value){
                    this.resetUserForum();
                }
            },
        },
        data(){
            return{
                forum: null,
                users: [],
                debounce: null,
                isShowInvite: false,
                user: null,
                // isWaitPayment: false
                ticket: null
            }
        }
    }
</script>

<style lang="scss" scoped>
    .search-user-box{
        position: relative;
    }
    .user-list{
        width: 100%;
        position: absolute;
        top: 44px;
        left: 0;
        background: #fff;
        border-radius: 4px;
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.08);
        max-height: 150px;
        &__item{
            padding: 0 12px;
            cursor: pointer;
            margin: 0;
            &:hover{
                background: #f0f0f0;
            }
        }
    }
    .user-item{
        display: flex;
        align-items: center;
        min-height: 50px;
        border-bottom: 1px solid #f0f0f0;
        padding: 8px 0 9px;
        img{
            width: 32px;
            height: 32px;
            margin-right: 10px;
            border-radius: 50%;
            overflow: hidden;
            object-fit: cover;
            flex: none;
        }
        &__wrap{
            flex: 1;
        }
    }
    .payment-cell{
        display: inline-block;
    }
</style>