<template>
    <b-modal id="update-visit" size="lg" centered hide-footer>
        <div class="modal-wrapper">
            <div class="h1">Изменение статуса посещения</div>
            <div v-if="userForum" class="user-wrap text-center">
                <div class="mb-4">
                    Изменить статус посещения пользователем <strong class="text-capitalize">{{userForum.user.lastName}} {{userForum.user.firstName}} {{userForum.user.patroName}}</strong>
                    ивента  <strong class="text-capitalize">{{userForum.forum.title}}</strong>
                </div>
                <div class="text-center mb-4">
                    <b-button type="button" variant="primary submit pl-5 pr-5 ml-2 mr-2" @click="handleStatus()">Подтвердить</b-button>
                    <b-button variant="outline-primary submit pl-5 pr-5 ml-2 mr-2" @click="$bvModal.hide('update-visit')">Отменить</b-button>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
    export default {
        name: "UpdateVisit",
        props: ['userForum'],
        methods: {
            handleStatus(){
                const data = {
                    itemId: this.userForum._id,
                    params: {
                        visit: this.userForum.isVisit
                    },
                    language: this.userForum.user.languageCode
                };
                this.$emit('updateVisitStatus', data);
                this.$bvModal.hide('update-visit');
            }
        },
    }
</script>

<style lang="scss" scoped>
    .h1{
        text-align: center;
        margin-bottom: 30px;
    }
    .user-wrap{
        max-width: 480px;
        margin: 0 auto;
    }
</style>