var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"invite-user","size":"xl","centered":"","hide-footer":"","no-close-on-backdrop":""},model:{value:(_vm.isShowInvite),callback:function ($$v) {_vm.isShowInvite=$$v},expression:"isShowInvite"}},[_c('div',{staticClass:"modal-wrapper"},[_c('div',{staticClass:"h1 text-center"},[_vm._v("Приглашение на событие")]),_c('ValidationObserver',{ref:"Invite",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var errors = ref.errors;
return _c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit($event)}}},[_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-lg-6"},[_c('label',[_vm._v("Название события")]),_c('ValidationProvider',{attrs:{"rules":"required","name":"Событие","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('div',{staticClass:"form-group"},[_c('v-select',{class:errors.length ? 'is-invalid' : '',attrs:{"placeholder":"Выберите событие","options":_vm.forums,"label":"title"},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_vm._v(" Извините, нет доступных событий ")]}},{key:"option",fn:function(ref){
var imagePath = ref.imagePath;
var title = ref.title;
return [_c('div',{staticClass:"forum-td"},[(imagePath)?_c('img',{attrs:{"src":imagePath,"alt":""}}):_vm._e(),_vm._v(" "+_vm._s(title)+" ")])]}}],null,true),model:{value:(_vm.forum),callback:function ($$v) {_vm.forum=$$v},expression:"forum"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-lg-6"},[_c('label',[_vm._v("ФИО пользователя")]),_c('user-search',{attrs:{"isRequire":true},model:{value:(_vm.user),callback:function ($$v) {_vm.user=$$v},expression:"user"}})],1),(_vm.forum && _vm.forum.price.length)?_c('div',{staticClass:"col-lg-6"},[_c('label',[_vm._v("Тип билета")]),_c('ValidationProvider',{attrs:{"rules":"required","name":"Тип билета","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('div',{staticClass:"form-group"},[_c('v-select',{class:errors.length ? 'is-invalid' : '',attrs:{"placeholder":"Выберите тип билета","options":_vm.forum.price,"label":"title"},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_vm._v(" Извините, нет доступных билетов ")]}},{key:"option",fn:function(ref){
var title = ref.title;
var amount = ref.amount;
return [_vm._v(" "+_vm._s(title)+" - "+_vm._s(amount)+" грн. ")]}}],null,true),model:{value:(_vm.ticket),callback:function ($$v) {_vm.ticket=$$v},expression:"ticket"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1):_vm._e()]),_c('div',{staticClass:"text-center mb-4"},[_c('b-button',{attrs:{"type":"submit","variant":"primary submit pl-5 pr-5"}},[_vm._v("пригласить")])],1)])}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }