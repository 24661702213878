<template>
    <ValidationProvider :rules="isRequire ? 'required' : ''" name="Пользователь" tag="div" mode="passive">
        <div class="form-group" slot-scope="{ valid, errors }">
            <v-select
                placeholder="Поиск пользователя по имени"
                :options="users"
                v-model="user"
                @search="handleSelectUser"
                :filterable="false"
                label="lastName"
                :class="errors.length ? 'is-invalid' : ''"
            >
                <template #no-options="{ search, searching, loading }">
                    Извините, нет доступных пользователей
                </template>

                <template #open-indicator="{ attributes }">
                    <b-icon icon="search"></b-icon>
                </template>

                <template #selected-option="user">
                    {{ user.lastName }} {{ user.firstName }} {{ user.patroName}}
                </template>

                <template #option="{ avatarPath, patroName, lastName, firstName, email }">
                    <div class="user-item">
                        <img v-if="avatarPath" :src="avatarPath" alt="">
                        <div class="user-item__wrap">
                            <span class="mr-3">{{ lastName }} {{ firstName }} {{ patroName}}</span><br>
                            {{ email.address }}
                        </div>
                    </div>
                </template>
            </v-select>
            <b-form-invalid-feedback>
                {{ errors[0] }}
            </b-form-invalid-feedback>
        </div>
    </ValidationProvider>
</template>

<script>
    import { mapActions } from 'vuex'

    export default {
        name: "UserSearch",
        props: {
            isRequire: {
                type: Boolean,
                required: true
            }
        },
        methods: {
            ...mapActions([
                'getUsers'
            ]),
            handleSelectUser(user, loading){
                if(user.length >= 3) {
                    clearTimeout(this.debounce);
                    this.debounce = setTimeout(() => {
                        loading(true);
                        const data = {
                            searchBy: 'fullName',
                            search: user.split(' ').filter(str => str !== '').join(' ')
                        };
                        this.getUsers(data).then(users => {
                            this.users = users.results;
                            loading(false);
                        })
                    }, 500)
                }
            }
        },
        watch: {
            user(newValue, oldValue){
                if(newValue && newValue !== oldValue){
                    this.$emit('input', newValue);
                } else {
                    this.$emit('input', null);
                }
                this.users = [];
            }
        },
        data(){
            return{
                user: null,
                users: [],
                debounce: null,
                userSelected: null
            }
        }
    }
</script>

<style lang="scss" scoped>
    .user-item{
        display: flex;
        align-items: center;
        min-height: 50px;
        margin: 0;
        img{
            width: 32px;
            height: 32px;
            margin-right: 10px;
            border-radius: 50%;
            overflow: hidden;
            object-fit: cover;
            flex: none;
        }
        &__wrap{
            flex: 1;
        }
    }
</style>