<template>
    <div class="dashboard">
        <user-request :forums="forums" :invite="invite"/>
        <invite-user :forums="forums" @invitedUser="invitedUser"/>
        <button
            v-b-modal.invite-user
            class="add"
        >
            <b-icon icon="plus-circle"></b-icon>
        </button>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'
    import Invite from "@/components/modals/InviteUser"
    import UserRequest from "@/components/registration/UserRequest"
    import InviteUser from "@/components/modals/InviteUser"

    export default {
        name: "registration",
        components: {
            InviteUser,
            UserRequest,
            Invite
        },
        computed: {
            getRequestForums(){
                const data = {
                    byLevelup: true,
                    perPage: 100500,
                    draft: false,
                };
                this.getForumsList(data).then(forums => {
                    this.forums = forums.results;
                })
            },
        },
        methods: {
            ...mapActions([
                'getForumsList'
            ]),
            invitedUser(invite){
                this.invite = invite;
                window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });
            }
        },
        mounted(){
            this.getRequestForums;
        },
        data(){
            return{
                invite: null,
                forums: []
            }
        }
    }
</script>

<style lang="scss" scoped>
    .dashboard{
        padding-bottom: 30px;
    }
</style>