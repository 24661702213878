<template>
    <div>
        <div class="wrapper">
            <div class="page-title">
                <div class="h1">Список регистраций</div>
                <div class="filter-box">
                    <div class="row">
                        <div class="col-xl-6 mt-1 mb-1">
                            <v-select placeholder="Выберите событие" :options="forums" v-model="selectForum" label="title">
                                <template #no-options="{ search, searching, loading }">
                                    Извините, нет доступных событий
                                </template>

                                <template #option="{ imagePath, title }">
                                    <div class="forum-td">
                                        <img v-if="imagePath" :src="imagePath" alt="">
                                        {{ title }}
                                    </div>
                                </template>
                            </v-select>
                        </div>
                        <div class="col-xl-6 mt-1 mb-1">
                            <user-search :isRequire="false" v-model="selectUser"/>
                        </div>
                    </div>
                </div>
            </div>

            <div class="auto-scroll-horizontal">
                <b-table
                    :busy="isBusy"
                    :filter="{selectUser, selectForum}"
                    :fields="fields"
                    :items="requestsProvider"
                    class="mb-0"
                    :current-page="currentPage"
                    :per-page="perPage"
                    empty-text="Нет доступных запросов"
                    show-empty
                    ref="requestTable"
                >
                    <template #cell(user.lastName)="data">
                        <div class="user-td" v-if="data.item.user">
                            <img v-if="data.item.user.avatarPath" :src="data.item.user.avatarPath" alt="">
                            <div class="user-name">
                                {{data.item.user.lastName}}
                                {{data.item.user.firstName}}
                                {{data.item.user.patroName}}
                            </div>
                        </div>
                        <strong v-else>-</strong>
                    </template>

                    <template #cell(user.email.address)="data">
                        <div v-if="data.item.user">
                            {{data.item.user.email.address}}
                        </div>
                        <strong v-else>-</strong>
                    </template>

                    <template #cell(forum.title)="data">
                        <div v-if="data.item.forum">{{data.item.forum.title}}</div>
                        <div v-else>-</div>
                    </template>

                    <template #cell(createdAt)="data">
                        {{parseDate(data.item.createdAt)}}
                    </template>

                    <template #cell(payment)="data">
                        <b-dropdown
                            block
                            :text="data.item.payment ? 'Оплачено' : 'Не оплачено'"
                            class="no-style"
                        >
                            <b-dropdown-item :active="data.item.payment" @click="handleEditPayment(data.item, true)">Оплачено</b-dropdown-item>
                            <b-dropdown-item :active="!data.item.payment" @click="handleEditPayment(data.item, false)">Не оплачено</b-dropdown-item>
                        </b-dropdown>
                    </template>

                    <template #cell(visit)="data">
                        <b-dropdown
                            block
                            :text="data.item.visit ? 'Посетил/а' : 'Не посетил/а'"
                            class="no-style"
                        >
                            <b-dropdown-item :active="data.item.visit" @click="handleVisit(data.item, true)">Посетил/а</b-dropdown-item>
                            <b-dropdown-item :active="!data.item.visit" @click="handleVisit(data.item, false)">Не посетил/а</b-dropdown-item>
                        </b-dropdown>
                    </template>

                    <template #cell(status)="data">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="tooltip-status" v-b-popover.hover="popoverConfig">
                                <request-status :status="data.item.status"/>
                            </div>
                            <span>
                                <button class="btn-crl" @click="handleRequest(data.item)">
                                    <b-icon icon="pencil-fill"></b-icon>
                                </button>
                            </span>
                        </div>
                    </template>

                    <template #cell(delete)="data">
                        <button class="btn-crl" @click="handleDeleteRequest(data.item._id)">
                            <b-icon icon="x-circle-fill"></b-icon>
                        </button>
                    </template>

                    <template #table-busy>
                        <div class="text-center my-2">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                    </template>

                    <template #empty="scope">
                        <div class="text-center my-2">
                            {{ scope.emptyText }}
                        </div>
                    </template>

                    <template #emptyfiltered="scope">
                        <div class="text-center my-2">
                            {{ scope.emptyText }}
                        </div>
                    </template>
                </b-table>
            </div>
            <update-payment :userForum="userForum" @updatePaymentStatus="updatePaymentStatus"/>
            <update-request :userForum="userForum" @updateRequestStatus="updateRequestStatus"/>
            <update-visit :userForum="userForum" @updateVisitStatus="updateVisitStatus"/>
        </div>

        <div v-if="totalRequests > perPage" class="pt-3">
            <b-pagination first-number last-number :total-rows="totalRequests" :per-page="perPage" v-model="currentPage"/>
        </div>
    </div>
</template>

<script>
    import parseDate from '@/mixins/parseDate'
    import UpdatePayment from "@/components/modals/UpdatePayment"
    import UpdateRequest from "@/components/modals/UpdateRequest"
    import UpdateVisit from "@/components/modals/UpdateVisit"
    import { mapActions } from 'vuex'
    import { errorsAlertApi } from '@/helpers/errorsAlertApi'
    import RequestStatus from "@/components/user/RequestStatus"
    import UserSearch from "@/components/forms/UserSearch"

    export default {
        name: "UserRequest",
        props: {
            invite: {
                type: Object
            },
            forums: {
                type: Array,
                required: true
            }
        },
        components: {
            RequestStatus,
            UpdatePayment,
            UpdateRequest,
            UpdateVisit,
            UserSearch
        },
        mixins: [parseDate],
        computed: {
            popoverConfig(){
                return {
                    html: true,
                    placement: 'bottom',
                    content: () => {
                        return `<strong>ПРИНЯТО</strong> - регистрация на событие принята;<br><strong>НА МОДЕРАЦИИ</strong> - регистрация в ожидании подтверждения;<br><strong>ОЖИДАЕТ ОПЛАТЫ</strong> - регистрация в ожидании оплаты;<br><strong>ОТКЛОНЕН</strong> - регистрация на событие отклонена;`
                    }
                }
            }
        },
        methods: {
            ...mapActions([
                'getUserForum',
                'setStateUserForum',
                'deleteRequestUserForum',
            ]),
            requestsProvider(ctx){
                const data = {
                    page: ctx.currentPage
                };

                if(ctx.sortBy){
                    data.sortBy = ctx.sortBy;
                    data.sort = ctx.sortDesc ? 'desc' : 'asc'
                }

                if(ctx.filter.selectUser){
                    data.userId = ctx.filter.selectUser._id;
                    data.page = 1;
                }

                if(ctx.filter.selectForum){
                    data.forumId = ctx.filter.selectForum._id;
                    data.page = 1;
                }

                return this.getUserForum(data).then(requestsApi => {
                    this.requests = requestsApi.results;
                    this.totalRequests = requestsApi.totalCount;
                    return this.requests
                })
                    .catch(error => {
                        errorsAlertApi(error);
                        return []
                    });
            },
            handleEditPayment(user, isPayment){
                if(isPayment !== user.payment){
                    const data = {
                        ...user, isPayment
                    };
                    this.userForum = data;
                    this.$bvModal.show('update-payment');
                }
            },
            handleRequest(user){
                this.userForum = user;
                this.$bvModal.show('update-request');
            },
            handleDeleteRequest(id){
                this.$swal({
                    icon: 'warning',
                    text: 'Вы действительно хотите удалить запрос?',
                    showCancelButton: true,
                    confirmButtonText: 'Да',
                    cancelButtonText: 'Отмена',
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.deleteRequestUserForum(id).then(() => {
                            this.requests = this.requests.filter(request => request._id !== id);
                            this.$refs.requestTable.refresh();
                        })
                    }
                })
            },
            updatePaymentStatus(payment){
                this.setStateUserForum(payment).then(userForumApi => {
                    for (let i = 0, len = this.requests.length; i < len; i++) {
                        const request = this.requests[i];
                        if(request._id === userForumApi._id){
                            Object.assign(this.requests[i], userForumApi);
                            break;
                        }
                    }
                })
                    .catch(error => {
                        errorsAlertApi(error);
                    });
            },
            updateRequestStatus(request){
                this.setStateUserForum(request).then(userForumApi => {
                    for (let i = 0, len = this.requests.length; i < len; i++) {
                        const request = this.requests[i];
                        if(request._id === userForumApi._id){
                            Object.assign(this.requests[i], userForumApi);
                            break;
                        }
                    }
                })
                    .catch(error => {
                        errorsAlertApi(error);
                    });
            },
            handleVisit(user, isVisit){
                if(isVisit !== user.visit){
                    const data = {
                        ...user, isVisit
                        };
                    this.userForum = data;
                    this.$bvModal.show('update-visit');
                }
            },
            updateVisitStatus(visit){
                this.setStateUserForum(visit).then(userForumApi => {
                    for (let i = 0, len = this.requests.length; i < len; i++) {
                        const request = this.requests[i];
                        if(request._id === userForumApi._id){
                            Object.assign(this.requests[i], userForumApi);
                            break;
                        }
                    }
                })
                    .catch(error => {
                        errorsAlertApi(error);
                    });
            }
        },
        watch: {
            invite(newValue, oldValue){
                if(newValue !== oldValue){
                    this.requests.push(newValue);
                    this.$refs.requestTable.refresh();
                }
            }
        },
        data(){
            return{
                isBusy: false,
                selectUser: null,
                forumsOption: [],
                selectForum: null,
                fields: [
                    {
                        key: 'user.lastName',
                        label: 'ФИО',
                        sortable: true,
                        tdClass: 'w-25'
                    }, {
                        key: 'user.email.address',
                        label: 'E-mail',
                        sortable: true
                    }, {
                        key: 'forum.title',
                        label: 'Событие',
                        sortable: true,
                    }, {
                        key: 'createdAt',
                        label: 'Дата',
                        sortable: true,
                    }, {
                        key: 'payment',
                        label: 'Оплата',
                        sortable: true,
                    }, {
                        key: 'visit',
                        label: 'Посещение',
                        sortable: true,
                    }, {
                        key: 'status',
                        label: 'Статус',
                        sortable: true,
                    }, {
                        key: 'delete',
                        label: '',
                        sortable: false,
                        tdClass: 'icon-action text-center'
                    }
                ],
                currentPage: 1,
                perPage: 10,
                totalRequests: 0,
                requests: [],
                userForum: null
            }
        }
    }
</script>

<style lang="scss" scoped>
    .page-title{
        display: flex;
        .h1{
            margin: 0;
        }
    }
    .filter-box{
        margin-left: auto;
        width: 740px;
    }
    .search-box{
        position: relative;
        .search-control{
            color: #013274;
            padding-right: 40px;
            border-color: #013274;
            &::placeholder{
                color: #013274;
            }
        }
        .bi{
            position: absolute;
            top:50%;
            transform: translateY(-50%);
            right: 10px;
            color: #013274;
        }
    }
    .user{
        &-td {
            display: flex;
            align-items: center;
            min-height: 48px;
            img {
                width: 48px;
                height: 48px;
                margin-right: 10px;
                border-radius: 50%;
                overflow: hidden;
                object-fit: cover;
                flex: none;
            }
        }
    }
    .tooltip-status{
        cursor: pointer;
    }
    @media screen and (max-width: 991px) {
        .page-title{
            flex-direction: column;
            .h1{
                margin-bottom: 10px;
            }
        }
        .filter-box{
            width: auto;
            margin: 0;
            max-width: 450px;
        }
    }
</style>