var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{attrs:{"rules":_vm.isRequire ? 'required' : '',"name":"Пользователь","tag":"div","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('div',{staticClass:"form-group"},[_c('v-select',{class:errors.length ? 'is-invalid' : '',attrs:{"placeholder":"Поиск пользователя по имени","options":_vm.users,"filterable":false,"label":"lastName"},on:{"search":_vm.handleSelectUser},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_vm._v(" Извините, нет доступных пользователей ")]}},{key:"open-indicator",fn:function(ref){
var attributes = ref.attributes;
return [_c('b-icon',{attrs:{"icon":"search"}})]}},{key:"selected-option",fn:function(user){return [_vm._v(" "+_vm._s(user.lastName)+" "+_vm._s(user.firstName)+" "+_vm._s(user.patroName)+" ")]}},{key:"option",fn:function(ref){
var avatarPath = ref.avatarPath;
var patroName = ref.patroName;
var lastName = ref.lastName;
var firstName = ref.firstName;
var email = ref.email;
return [_c('div',{staticClass:"user-item"},[(avatarPath)?_c('img',{attrs:{"src":avatarPath,"alt":""}}):_vm._e(),_c('div',{staticClass:"user-item__wrap"},[_c('span',{staticClass:"mr-3"},[_vm._v(_vm._s(lastName)+" "+_vm._s(firstName)+" "+_vm._s(patroName))]),_c('br'),_vm._v(" "+_vm._s(email.address)+" ")])])]}}],null,true),model:{value:(_vm.user),callback:function ($$v) {_vm.user=$$v},expression:"user"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}])})}
var staticRenderFns = []

export { render, staticRenderFns }